/*eslint-disable @typescript-eslint/explicit-member-accessibility*/
import {Tooltip} from 'wix-ui-tpa/Tooltip';
import React from 'react';
import {Placement} from 'wix-ui-core/dist/es/src';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';

export enum DataHook {
  errorTooltip = 'error-tooltip',
}

export interface ErrorTooltipProviderProps {
  className?: string;
  minWidth?: number;
  children?: React.ReactNode;
  content: string;
  show: boolean;
  placement: Placement;
  appendTo?: string;
}
@withGlobalProps
export class ErrorTooltipProvider extends React.Component<ErrorTooltipProviderProps & ProvidedGlobalProps> {
  render() {
    const {
      content,
      placement,
      show,
      children,
      className,
      minWidth,
      appendTo,
      globals: {isMobile},
    } = this.props;
    /* istanbul ignore next: todo: fix testkit for tooltip */
    const point = {x: isMobile ? 0 : 4, y: 0};

    return (
      <Tooltip
        appendTo={appendTo as any}
        moveBy={point}
        minWidth={minWidth}
        className={className}
        maxWidth={360}
        content={content}
        placement={placement}
        skin={'error' as any}
        disabled={!show}
        shown={show}
        data-hook={DataHook.errorTooltip}
        showArrow={true}>
        {children}
      </Tooltip>
    );
  }
}
