import React from 'react';
import classNames from 'classnames';
import {classes} from '../BuyNowButton/BuyNowButton.st.css';
import * as productPageButtonsContainerStyles from '../ProductPageButtonsContainer.scss';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {Button, SIZE} from 'wix-ui-tpa/Button';
import {ErrorNames, ModalState} from '../../../constants';

export interface SubscribeButtonProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  productInStock: boolean;
}

export enum DataHook {
  subscribeButton = 'subscribe-button',
}

@withGlobalProps
@withTranslations('globals.texts')
export class SubscribeButton extends React.Component<SubscribeButtonProps> {
  public SubscribeButtonRef = React.createRef<HTMLButtonElement>();

  public componentDidUpdate(prevProps: Readonly<SubscribeButtonProps>): void {
    if (prevProps.globals.modalState === ModalState.OPEN && this.props.globals.modalState === ModalState.CLOSE) {
      this.focusButton();
    }
  }

  public focusButton = () => {
    this.SubscribeButtonRef.current.focus();
  };

  public render(): JSX.Element {
    const {
      t,
      productInStock,
      globals: {product, selectedVariant, shouldShowWishlistButton, handleSubscribe, accessibilityEnabled, errors},
    } = this.props;
    const {price} = selectedVariant || product;

    const disabled = !productInStock || price <= 0;
    let caption = t('PRODUCT_PAGE_SUBSCRIBE_NOW_BUTTON');

    if (disabled) {
      caption =
        /* istanbul ignore next: todo: test */ price > 0 || !productInStock
          ? t('PRODUCT_OUT_OF_STOCK_BUTTON')
          : t('ADD_TO_CART_BUTTON_PRICE_ZERO');
    }

    if (errors.errorName === ErrorNames.StoreInfoError) {
      caption = 'try again'; //todo(ariell): change to translation
    }

    const buttonClasses = classNames(classes.buyNowButton, {
      [productPageButtonsContainerStyles.primaryButton]: shouldShowWishlistButton,
    });

    return (
      <Button
        type="button"
        ref={this.SubscribeButtonRef}
        onClick={() => handleSubscribe(accessibilityEnabled)}
        disabled={disabled}
        data-hook={DataHook.subscribeButton}
        className={buttonClasses}
        size={SIZE.large}
        fullWidth={!shouldShowWishlistButton}>
        {caption}
      </Button>
    );
  }
}
